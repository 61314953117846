 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="120px">
			<el-form-item class="el_form_item" label="APP用户手机">
				<el-input class="el_input" v-model="form.app_user_tel" placeholder="注册人手机号码搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="APP用户身份证">
				<el-input class="el_input" v-model="form.app_user_id_card_num" placeholder="注册人身份证搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="公司名称">
				<el-input class="el_input" v-model="form.name" placeholder="公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="社会代码">
				<el-input class="el_input" v-model="form.license_code" placeholder="统一社会信用代码搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="销售电话">
				<el-input class="el_input" v-model="form.saler_tel" placeholder="销售电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运营电话">
				<el-input class="el_input" v-model="form.admin_tel" placeholder="运营电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="代理电话">
				<el-input class="el_input" v-model="form.agent_tel" placeholder="代理电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="合同上传状态">
				<el-select class="el_input" v-model="form.img_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上传" value="0"></el-option>
					<el-option label="已上传" value="1"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="所属大区">
				<el-select class="el_input" v-model="form.area_id" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="item in area_list" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.check_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="已通过" value="2"></el-option>
					<el-option label="未通过" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注搜索">
				<el-input class="el_input" v-model="form.company_msg" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="注册时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item" >
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="get_excle_list">全部导出</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="公司id" width="60">
					<template slot-scope="scope">
						<div>{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时间相关" width="170">
					<template slot-scope="scope">
						<div>注册:{{scope.row.creat_time_text}}</div>
						<div>通过:{{scope.row.pass_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="用户姓名/电话" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.app_user_info.name}}</div>
						<div>{{scope.row.app_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="公司/法人/信用代码" width="180">
					<template slot-scope="scope">
						<div>公司: {{scope.row.name}}</div>
						<div>法人: {{scope.row.legal_person}}</div>
						
					</template>
				</el-table-column>
				<el-table-column label="服务费率" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.shipper_set_info.extra_service_charge_rate}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="对公银行/卡号" width="280">
					<template slot-scope="scope">
						<div>{{scope.row.bank_name}}</div>
						<div>{{scope.row.bank_card}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="对公余额">
					<template slot-scope="scope">
						<div>{{scope.row.app_user_info.recharge_balance}}</div>
					</template>
				</el-table-column>
				<el-table-column label="公司地址/电话" width="250">
					<template slot-scope="scope">
						
						
						<div>税号:{{scope.row.license_code}}</div>
						<div>银行:{{scope.row.bank_name}}</div>
						<div>账号:{{scope.row.bank_card}}</div>
						<div>电话:{{scope.row.tel}}</div>
						<div>地址:{{scope.row.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="所属大区">
					<template slot-scope="scope">
						<div>{{scope.row.area_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="销售绑定">
					<template slot-scope="scope">
						<div v-if="scope.row.saler_info">{{scope.row.saler_info.name}}/{{scope.row.saler_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="代理绑定">
					<template slot-scope="scope">
						<div v-if="scope.row.agent_info">{{scope.row.agent_info.name}}/{{scope.row.agent_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="所属运营">
					<template slot-scope="scope">
						<div v-if="scope.row.admin_info">{{scope.row.admin_info.name}}/{{scope.row.admin_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="公司备注">
					<template slot-scope="scope">
						<div>{{scope.row.company_msg}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="check_status_text" width="70"></el-table-column>
				
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	import { Loading } from "element-ui";
    import {mapState} from 'vuex'
	export default {
		components:{
		},
		data() {
			return {
				
				//大区列表
				area_list:[
					{name:"湖南大区",id:7},
					{name:"贵州大区",id:8},
					{name:"安徽大区",id:9},
					{name:"河南大区",id:3},
				],
				//搜索条件
				form: {
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					name:'',//公司名称
					license_code:'',//是否可用
					check_status:'',//身份证号
					area_id:"不限", // 所属大区id
					company_msg:'',//备注
					saler_tel:'',//销售电话
					admin_tel:'',//运营电话
					agent_tel:'',//代理手机号
					img_status:'',//合同上传状态
					creat_time_start:'',//注册开始时间
					creat_time_end:'',//注册结束时间
				},
				creat_time:'',//注册时间
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				excle_list:[],
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
			}
		},
		created() {

			//读取页面数据
			this.region_query()
		},	
        computed:{
			...mapState(['user_info'])
		},
		methods: {
			get_excle_list(){
				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'company_list_admin',
						is_get_user_info:1,
						...this.form,
						...this.page,
						num:1000,
					},
					callback:(data)=>{
						//预处理
						for(let item of data.list){
								
							item.area_info=item.area_info?item.area_info:{name:"未设置"}

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							item.pass_time_text=(item.pass_time>0)?this.$my.other.totime(item.pass_time):"";
							if(item.check_status==3){//打回状态。不显示时间
								item.pass_time_text='';
							}

							//实名状态
							switch(item.check_status){
								case '1':item.check_status_text='审核中';break;
								case '2':item.check_status_text='审核通过';break;
								case '3':item.check_status_text='审核驳回';break;
							}

							//服务费计算方式
							switch(item.service_charge_type){
								case '1':item.service_charge_type_text='除法模式';break;
								case '2':item.service_charge_type_text='乘法模式';break;
							}
							//合同签署状态
							switch(item.img_status){
								case '0':item.img_status_text='未签署';break;
								case '1':item.img_status_text='已签署';break;
							}
							item.user_tel = item.app_user_info.tel
							item.user_name = item.app_user_info.name
							item.recharge_balance = item.app_user_info.recharge_balance
							item.belong=item.area_info.name
							if(item.agent_info){
								item.agent_name = item.agent_info.name
								item.agent_tel = item.agent_info.tel
							}else{
								item.agent_name = ''
								item.agent_tel = ''
							}
							if( item.saler_info){
								item.saler_name = item.saler_info.name
								item.saler_tel = item.saler_info.tel
							}else{
								item.saler_name = ''
								item.saler_tel = ''
							}
							
							if(item.shipper_set_info){
								item.extra_service_charge_rate = item.shipper_set_info.extra_service_charge_rate
							}else{
								item.extra_service_charge_rate = ''
							}
						}
						this.tord_whole_excle(data.list)
					}
				})
			},
	      	tord_whole_excle(data){
				var shunxu=[
					{key:"id",cname:"系统id"},
					{key:"name",cname:"公司名称"},
					{key:"legal_person",cname:"公司法人"},
					{key:"license_code",cname:"信用代码"},
					{key:"creat_time_text",cname:"提交时间"},
					{key:"pass_time_text",cname:"通过时间"},
                    {key:"img_status_text",cname:"合同签署状态"},
					{key:"saler_name",cname:"绑定销售"},
					{key:"saler_tel",cname:"绑定销售电话"},
					{key:"agent_name",cname:"绑定代理"},
					{key:"agent_tel",cname:"绑定代理电话"},
					{key:"user_name",cname:"用户姓名"},
					{key:"user_tel",cname:"用户电话"},
					{key:"bank_name",cname:"对公银行"},
					{key:"bank_card",cname:"对公卡号"},
					{key:"recharge_balance",cname:"对公余额"},
					{key:"extra_service_charge_rate",cname:"服务税率"},
					{key:"addr",cname:"公司地址"},
					{key:"tel",cname:"公司电话"},
					{key:"belong",cname:"所属大区"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "公司列表.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				// let loadingInstance = Loading.service(this.options);
				// 	this.$nextTick(() => { 
				// 	loadingInstance.close();
				// });
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					name:'',//公司名称
					license_code:'',//是否可用
					check_status:'',//身份证号
					company_msg:'',//备注
					saler_tel:'',//销售电话
					admin_tel:'',//运营电话
					img_status:'',//合同上传状态
					agent_tel:'',
					creat_time_start:'',//注册开始时间
					creat_time_end:'',//注册结束时间
				}
				this.creat_time = ''
				this.region_query()
			},

			//搜索
			page_ser(){
				if (this.creat_time) {
					this.form.creat_time_start = parseInt(this.creat_time[0] / 1000);
					this.form.creat_time_end= parseInt(this.creat_time[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				//读取第一页
				this.page.p=1

				//读取
				this.region_query()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.region_query()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.region_query()
			},
            //先用手机号查询所属大区
            region_query(){
				//读取数据
				this.$my.net.req({
                    take_over_control:1,
					data:{
						mod:'app_user',
						ctr:'app_user_saler_list',
						tel:this.user_info.tel ,
						...this.page
					},
					callback:(data)=>{
                      if(data.msg.list){
                            var list = data.msg.list.list
                           //置入数据
                            this.form.area_id = list[0].area_id
                            //查询
                             this.get_page_data()
                      }else{
                          //没有查询到销售
                             this.$my.other.msg({
                                    type:'info',
                                    str:'查询为空'
                            });
                      }
                    
                       
					}
				})
            },
			//读取页面数据
			get_page_data(){
				//加载中...
				if(this.list.loading){
					return;
				}
				
				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'company_list_admin',
						is_get_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){
							
							item.area_info=item.area_info?item.area_info:{name:"未设置"}

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							item.pass_time_text=(item.pass_time>0)?this.$my.other.totime(item.pass_time):"";
							if(item.check_status==3){//打回状态。不显示时间
								item.pass_time_text='';
							}

							//实名状态
							switch(item.check_status){
								case '1':item.check_status_text='审核中';break;
								case '2':item.check_status_text='审核通过';break;
								case '3':item.check_status_text='审核驳回';break;
							}
							//e签宝合同状态签署
							switch(item.e_status){
								case '0':item.e_status_text='未使用e签宝';break;
								case '1':item.e_status_text='e签宝待签';break;
								case '2':item.e_status_text='e签宝已签';break;
								case '3':item.e_status_text='e签宝拒签';break;
							}
							//服务费计算方式
							switch(item.service_charge_type){
								case '1':item.service_charge_type_text='除法模式';break;
								case '2':item.service_charge_type_text='乘法模式';break;
							}
							switch(item.saler_info.type){
								case '1':item.saler_info_type_text='销售';break;
								case '2':item.saler_info_type_text='代理';break;
							}
						}
						this.page.num =10
						this.excle_list = data.list
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 300px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	#pdf_upload{
		margin-right: 100%;
	}
</style>